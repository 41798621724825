exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customers-2022-07-09-ttt-md": () => import("./../../../src/pages/customers/2022-07-09-ttt.md" /* webpackChunkName: "component---src-pages-customers-2022-07-09-ttt-md" */),
  "component---src-pages-en-about-about-md": () => import("./../../../src/pages/en/about/about.md" /* webpackChunkName: "component---src-pages-en-about-about-md" */),
  "component---src-pages-en-about-index-js": () => import("./../../../src/pages/en/about/index.js" /* webpackChunkName: "component---src-pages-en-about-index-js" */),
  "component---src-pages-en-automation-2022-07-12-intelligent-automation-md": () => import("./../../../src/pages/en/automation/2022-07-12-intelligent-automation.md" /* webpackChunkName: "component---src-pages-en-automation-2022-07-12-intelligent-automation-md" */),
  "component---src-pages-en-automation-index-js": () => import("./../../../src/pages/en/automation/index.js" /* webpackChunkName: "component---src-pages-en-automation-index-js" */),
  "component---src-pages-en-blog-index-js": () => import("./../../../src/pages/en/blog/index.js" /* webpackChunkName: "component---src-pages-en-blog-index-js" */),
  "component---src-pages-en-career-2022-07-07-test-md": () => import("./../../../src/pages/en/career/2022-07-07-test.md" /* webpackChunkName: "component---src-pages-en-career-2022-07-07-test-md" */),
  "component---src-pages-en-career-index-js": () => import("./../../../src/pages/en/career/index.js" /* webpackChunkName: "component---src-pages-en-career-index-js" */),
  "component---src-pages-en-contact-2022-07-07-test-md": () => import("./../../../src/pages/en/contact/2022-07-07-test.md" /* webpackChunkName: "component---src-pages-en-contact-2022-07-07-test-md" */),
  "component---src-pages-en-contact-index-js": () => import("./../../../src/pages/en/contact/index.js" /* webpackChunkName: "component---src-pages-en-contact-index-js" */),
  "component---src-pages-en-cookie-concent-2022-07-19-map-templatekey-encookie-approvetext-i-approve-text-we-use-cookies-to-give-you-a-better-experience-of-zimply-md": () => import("./../../../src/pages/en-cookie-concent/2022-07-19-map-templatekey-encookie-approvetext-i-approve-text-we-use-cookies-to-give-you-a-better-experience-of-zimply.md" /* webpackChunkName: "component---src-pages-en-cookie-concent-2022-07-19-map-templatekey-encookie-approvetext-i-approve-text-we-use-cookies-to-give-you-a-better-experience-of-zimply-md" */),
  "component---src-pages-en-createassistant-2022-07-07-test-md": () => import("./../../../src/pages/en/createassistant/2022-07-07-test.md" /* webpackChunkName: "component---src-pages-en-createassistant-2022-07-07-test-md" */),
  "component---src-pages-en-createassistant-index-js": () => import("./../../../src/pages/en/createassistant/index.js" /* webpackChunkName: "component---src-pages-en-createassistant-index-js" */),
  "component---src-pages-en-demo-2022-07-18-book-a-demo-md": () => import("./../../../src/pages/en/demo/2022-07-18-book-a-demo.md" /* webpackChunkName: "component---src-pages-en-demo-2022-07-18-book-a-demo-md" */),
  "component---src-pages-en-demo-index-js": () => import("./../../../src/pages/en/demo/index.js" /* webpackChunkName: "component---src-pages-en-demo-index-js" */),
  "component---src-pages-en-digitalassistants-2022-07-07-test-md": () => import("./../../../src/pages/en/digitalassistants/2022-07-07-test.md" /* webpackChunkName: "component---src-pages-en-digitalassistants-2022-07-07-test-md" */),
  "component---src-pages-en-digitalassistants-index-js": () => import("./../../../src/pages/en/digitalassistants/index.js" /* webpackChunkName: "component---src-pages-en-digitalassistants-index-js" */),
  "component---src-pages-en-footer-footer-md": () => import("./../../../src/pages/en-footer/footer.md" /* webpackChunkName: "component---src-pages-en-footer-footer-md" */),
  "component---src-pages-en-home-md": () => import("./../../../src/pages/en/home.md" /* webpackChunkName: "component---src-pages-en-home-md" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-navbar-ennavbar-md": () => import("./../../../src/pages/en-navbar/ennavbar.md" /* webpackChunkName: "component---src-pages-en-navbar-ennavbar-md" */),
  "component---src-pages-en-notfound-2022-07-15-cms-md": () => import("./../../../src/pages/en-notfound/2022-07-15-cms.md" /* webpackChunkName: "component---src-pages-en-notfound-2022-07-15-cms-md" */),
  "component---src-pages-en-success-2022-08-15-ttt-md": () => import("./../../../src/pages/en/success/2022-08-15-ttt.md" /* webpackChunkName: "component---src-pages-en-success-2022-08-15-ttt-md" */),
  "component---src-pages-en-success-index-js": () => import("./../../../src/pages/en/success/index.js" /* webpackChunkName: "component---src-pages-en-success-index-js" */),
  "component---src-pages-en-team-2022-07-07-test-md": () => import("./../../../src/pages/en/team/2022-07-07-test.md" /* webpackChunkName: "component---src-pages-en-team-2022-07-07-test-md" */),
  "component---src-pages-en-team-index-js": () => import("./../../../src/pages/en/team/index.js" /* webpackChunkName: "component---src-pages-en-team-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-crypto-in-numbers-js": () => import("./../../../src/pages/main/CryptoInNumbers.js" /* webpackChunkName: "component---src-pages-main-crypto-in-numbers-js" */),
  "component---src-pages-main-end-quote-js": () => import("./../../../src/pages/main/EndQuote.js" /* webpackChunkName: "component---src-pages-main-end-quote-js" */),
  "component---src-pages-main-faq-js": () => import("./../../../src/pages/main/FAQ.js" /* webpackChunkName: "component---src-pages-main-faq-js" */),
  "component---src-pages-main-features-js": () => import("./../../../src/pages/main/Features.js" /* webpackChunkName: "component---src-pages-main-features-js" */),
  "component---src-pages-main-fi-js": () => import("./../../../src/pages/main/FI.js" /* webpackChunkName: "component---src-pages-main-fi-js" */),
  "component---src-pages-main-hero-js": () => import("./../../../src/pages/main/Hero.js" /* webpackChunkName: "component---src-pages-main-hero-js" */),
  "component---src-pages-main-index-js": () => import("./../../../src/pages/main/index.js" /* webpackChunkName: "component---src-pages-main-index-js" */),
  "component---src-pages-main-portfolio-js": () => import("./../../../src/pages/main/Portfolio.js" /* webpackChunkName: "component---src-pages-main-portfolio-js" */),
  "component---src-pages-main-sign-up-js": () => import("./../../../src/pages/main/SignUp.js" /* webpackChunkName: "component---src-pages-main-sign-up-js" */),
  "component---src-pages-main-understand-js": () => import("./../../../src/pages/main/Understand.js" /* webpackChunkName: "component---src-pages-main-understand-js" */),
  "component---src-pages-portfolios-2022-05-03-balanserade-md": () => import("./../../../src/pages/portfolios/2022-05-03-balanserade.md" /* webpackChunkName: "component---src-pages-portfolios-2022-05-03-balanserade-md" */),
  "component---src-pages-portfolios-2022-05-03-explosiva-md": () => import("./../../../src/pages/portfolios/2022-05-03-explosiva.md" /* webpackChunkName: "component---src-pages-portfolios-2022-05-03-explosiva-md" */),
  "component---src-pages-portfolios-2022-05-03-stabila-md": () => import("./../../../src/pages/portfolios/2022-05-03-stabila.md" /* webpackChunkName: "component---src-pages-portfolios-2022-05-03-stabila-md" */),
  "component---src-pages-sv-about-2022-06-15-map-md": () => import("./../../../src/pages/sv/about/2022-06-15-map.md" /* webpackChunkName: "component---src-pages-sv-about-2022-06-15-map-md" */),
  "component---src-pages-sv-about-index-js": () => import("./../../../src/pages/sv/about/index.js" /* webpackChunkName: "component---src-pages-sv-about-index-js" */),
  "component---src-pages-sv-automation-2022-07-10-intelligent-automation-md": () => import("./../../../src/pages/sv/automation/2022-07-10-intelligent-automation.md" /* webpackChunkName: "component---src-pages-sv-automation-2022-07-10-intelligent-automation-md" */),
  "component---src-pages-sv-automation-index-js": () => import("./../../../src/pages/sv/automation/index.js" /* webpackChunkName: "component---src-pages-sv-automation-index-js" */),
  "component---src-pages-sv-blog-index-js": () => import("./../../../src/pages/sv/blog/index.js" /* webpackChunkName: "component---src-pages-sv-blog-index-js" */),
  "component---src-pages-sv-career-2022-07-05-jobba-pa-zimply-md": () => import("./../../../src/pages/sv/career/2022-07-05-jobba-på-zimply.md" /* webpackChunkName: "component---src-pages-sv-career-2022-07-05-jobba-pa-zimply-md" */),
  "component---src-pages-sv-career-index-js": () => import("./../../../src/pages/sv/career/index.js" /* webpackChunkName: "component---src-pages-sv-career-index-js" */),
  "component---src-pages-sv-contact-2022-06-15-contact-md": () => import("./../../../src/pages/sv/contact/2022-06-15-contact.md" /* webpackChunkName: "component---src-pages-sv-contact-2022-06-15-contact-md" */),
  "component---src-pages-sv-contact-index-js": () => import("./../../../src/pages/sv/contact/index.js" /* webpackChunkName: "component---src-pages-sv-contact-index-js" */),
  "component---src-pages-sv-cookie-concent-2022-07-19-map-templatekey-svcookie-text-vi-anvander-cookies-for-att-ge-dig-en-battre-upplevelse-av-zimply-approvetext-jag-godkanner-md": () => import("./../../../src/pages/sv-cookie-concent/2022-07-19-map-templatekey-svcookie-text-vi-använder-cookies-för-att-ge-dig-en-bättre-upplevelse-av-zimply-approvetext-jag-godkänner.md" /* webpackChunkName: "component---src-pages-sv-cookie-concent-2022-07-19-map-templatekey-svcookie-text-vi-anvander-cookies-for-att-ge-dig-en-battre-upplevelse-av-zimply-approvetext-jag-godkanner-md" */),
  "component---src-pages-sv-createassistant-2022-06-21-skapa-assistent-md": () => import("./../../../src/pages/sv/createassistant/2022-06-21-skapa-assistent.md" /* webpackChunkName: "component---src-pages-sv-createassistant-2022-06-21-skapa-assistent-md" */),
  "component---src-pages-sv-createassistant-index-js": () => import("./../../../src/pages/sv/createassistant/index.js" /* webpackChunkName: "component---src-pages-sv-createassistant-index-js" */),
  "component---src-pages-sv-demo-2022-07-09-boka-en-demo-md": () => import("./../../../src/pages/sv/demo/2022-07-09-boka-en-demo.md" /* webpackChunkName: "component---src-pages-sv-demo-2022-07-09-boka-en-demo-md" */),
  "component---src-pages-sv-demo-index-js": () => import("./../../../src/pages/sv/demo/index.js" /* webpackChunkName: "component---src-pages-sv-demo-index-js" */),
  "component---src-pages-sv-digitalassistants-2022-06-17-digitatawe-md": () => import("./../../../src/pages/sv/digitalassistants/2022-06-17-digitatawe.md" /* webpackChunkName: "component---src-pages-sv-digitalassistants-2022-06-17-digitatawe-md" */),
  "component---src-pages-sv-digitalassistants-index-js": () => import("./../../../src/pages/sv/digitalassistants/index.js" /* webpackChunkName: "component---src-pages-sv-digitalassistants-index-js" */),
  "component---src-pages-sv-footer-footer-md": () => import("./../../../src/pages/sv-footer/footer.md" /* webpackChunkName: "component---src-pages-sv-footer-footer-md" */),
  "component---src-pages-sv-home-md": () => import("./../../../src/pages/sv/home.md" /* webpackChunkName: "component---src-pages-sv-home-md" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-sv-navbar-2022-06-14-ttt-md": () => import("./../../../src/pages/sv-navbar/2022-06-14-ttt.md" /* webpackChunkName: "component---src-pages-sv-navbar-2022-06-14-ttt-md" */),
  "component---src-pages-sv-notfound-2022-07-15-cms-md": () => import("./../../../src/pages/sv-notfound/2022-07-15-cms.md" /* webpackChunkName: "component---src-pages-sv-notfound-2022-07-15-cms-md" */),
  "component---src-pages-sv-success-2022-08-15-ttt-md": () => import("./../../../src/pages/sv/success/2022-08-15-ttt.md" /* webpackChunkName: "component---src-pages-sv-success-2022-08-15-ttt-md" */),
  "component---src-pages-sv-success-index-js": () => import("./../../../src/pages/sv/success/index.js" /* webpackChunkName: "component---src-pages-sv-success-index-js" */),
  "component---src-pages-sv-team-2022-06-15-team-md": () => import("./../../../src/pages/sv/team/2022-06-15-team.md" /* webpackChunkName: "component---src-pages-sv-team-2022-06-15-team-md" */),
  "component---src-pages-sv-team-index-js": () => import("./../../../src/pages/sv/team/index.js" /* webpackChunkName: "component---src-pages-sv-team-index-js" */),
  "component---src-templates-automation-template-js": () => import("./../../../src/templates/AutomationTemplate.js" /* webpackChunkName: "component---src-templates-automation-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/CaseTemplate.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-job-template-js": () => import("./../../../src/templates/JobTemplate.js" /* webpackChunkName: "component---src-templates-job-template-js" */)
}

