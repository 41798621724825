
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

import './src/assets/css/bootstrap.min.css'
import './src/assets/css/custom-animated.css'
import './src/assets/css/default.css'
import './src/assets/css/font-awesome.min.css'
import './src/assets/css/magnific-popup.css'
import './src/assets/css/magnific.dark.css'
import './src/assets/css/magnific.rtl.css'
import './src/assets/css/main.css'
import './src/assets/css/style.css'
import './src/assets/css/style.css'