import React, { Component, createContext } from "react";
import { navigate } from 'gatsby';

export const LangugaeContext = createContext({ 
  swedish: true,
  toggleLanguage: () => { },
  constructPath: () => {},
  navigateHome: () => {},
  getCorrectPost: () => { }
});

class LanguageProvider extends Component {

  componentDidMount() {
    let url = window.location.pathname;
    let swe = url.includes("/se");
    let eng = url.includes("/en");
    if(swe){
      this.setState({ swedish: true });
    }
    else if(eng) {
      this.setState({ swedish: false });
    }
  }

  toggleLanguage = () => {
    this.setState({ swedish: !this.state.swedish },
      function() { 
        let url = window.location.pathname;
        var newUrl = url.replace('/sv/', '');
        var newUrl2 = newUrl.replace('/en/', '');
        var newUrl3 = newUrl2.replace('/sv', '');
        var newUrl4 = newUrl3.replace('/en', '');
        let lang = this.state.swedish ? 'sv' : 'en';
        navigate(`/${lang}/${newUrl4}`);  
    });
  };

  constructPath(path, language){

    let lang = language?.swedish ? 'sv' : 'en';

    if(path == "/"){
      navigate(`/${lang}`); 
    }
    else{
      navigate(`/${lang}/${path}`);  
    }
  }

  navigateHome(language){
    let lang = language.swedish ? 'sv' : 'en';
    navigate(`/${lang}`); 
  }

  getCorrectPost = (svData, enData, wind) => {

    //let urlSplit = null;

   // if (typeof window !== `undefined`){
      
   //let urlSplit = window.location.pathname.split("/")
        let cmsData = svData;
        this.state.swedish ? null : cmsData = enData;
    
        let urlSplit = wind.location.pathname.split("/")
        let lastSplit = urlSplit.pop()
        let secondLastSplit = urlSplit[urlSplit.length - 1]
      
        if(!lastSplit){
          lastSplit = secondLastSplit;
        }
    
        let thePost = null
    
        cmsData.map(post => {
          let rawSlug = post.node.fields.slug;
          let slug = rawSlug.replaceAll('/', '');
    
          if(slug === lastSplit){
            thePost = post.node.frontmatter;
          }
        })
    
        return thePost;
    //}    
  }

  state = {
    swedish: true,
    toggleLanguage: this.toggleLanguage,
    constructPath: this.constructPath,
    navigateHome: this.navigateHome,
    getCorrectPost: this.getCorrectPost
  };

  /*componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      const user = await generateUserDocument(userAuth);
      this.setState({ user });
    });
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }*/

  render() {
    return (
      <LangugaeContext.Provider value={this.state}>
        {this.props.children}
      </LangugaeContext.Provider>
    );
  }
}

export default LanguageProvider;